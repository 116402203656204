import axios from "@/util/request.js"

//免费试听列表
export function freeclassApi(params) {
    return axios.service({
        url: '/chapters/getFreeCourse',
        method: 'post',
        params: params
    });

}
//首页接口
export function getIndex(params) {
    return axios.service({ 
        url: '/index',
        method: 'post',
        params: params
    });
}
//选课中心
export function courseSelectCenter() {
    return axios.service({
        url: '/courses/courseSelectCenter',
        method: 'post'
    });
}
// // 课程推荐
export function recommend() {
    return axios.service({
        url: '/courses/recommend',
        method: 'post',
    });
}
//课程详情
export function getCourseDetailsApi(params) {
    return axios.service({
        url: '/courses/getCourseDetails',
        method: 'post',
        params: params
    });
}

//个人中心接口
export function getUserInfo(params) {
    return axios.service({
        url: '/users/getUserInfo',
        method: 'post',
        params: params
    });
}
//获取免费试听播放参数
export function getFreeTrialPlay(params) {
    return axios.service({
        url: '/chapters/getFreeTrialPlay',
        method: 'post',
        params: params
    });
}
//获取学习中心播放参数
export function getChaptersPlay(params) {
    return axios.service({
        url: '/chapters/getChaptersPlay',
        method: 'post',
        params: params
    });
}
//获取免费试听视频章节列表
export function getChapter(params) {
    return axios.service({
        url: '/chapters/getChapter',
        method: 'post',
        params: params
    });
}
// 我的订单接口
export function getMyOrdersApi(params) {
    return axios.service({
        url: '/orders/getMyOrders',
        method: 'post',
        params: params
    });
}
// 取消订单接口
export function removeOrders(params) {
    return axios.service({
        url: '/orders/wechatpay/remove',
        method: 'post',
        params: params
    });
}
// export function getMyOrdersApi(params) {
//     return axios.service({
//         url: '/orders/getMyOrders',
//         method: 'post',
//         params: params
//     });
// }
// //下单支付接口
// export function generateOrder(params) {
//     return axios.service({
//         url: '/orders/wechatpay/generateOrder',
//         method: 'post',
//         params: params
//     });
// }

// 学习中心
export function learningCenter(params) {
    return axios.service({
        url: '/courses/learningCenter',
        method: 'post',
        params: params
    });
}
//获取学习中心章节列表
export function getCourseChapter(params) {
    return axios.service({
        url: '/chapters/getCourseChapter',
        method: 'post',
        params: params
    });
}

//学习中心记录看课与校验互踢
export function verifyMultipleClients(params) {
    return axios.service({
        url: '/chapters/verifyMultipleClients',
        method: 'post',
        params: params
    });
}

//下载手机端接口
export function downloadMobileTerminal() {
    return axios.service({
        url: '/app-version/getLatestVersion',
        method: 'get', 
        // date:data
    });
}

