import { createStore } from 'vuex'
import { getUserInfo } from "@/api/free"
// import { ElNotification } from 'element-plus'
// 类似 Redux 中的建立状态树

export default createStore({
  
  // 1、 存储所有全局数据
  state: {
    userInfo:{},
    isUserInfo:false
  },
 
  getters: {
   
  },
  actions: {
    async getUserList({commit}){
        getUserInfo().then(res =>{
            if (res.code == "200") {
                // sessionStorage.setItem('userInfo',JSON.stringify(res.data))
                localStorage.setItem('userInfo',JSON.stringify(res.data))
                commit('SET_USERINFO',res.data)
            }
        })
    }
},
 mutations: {
  SET_USERINFO:(state,newVAL)=>{
      state.userInfo = newVAL
      state.isUserInfo = true
  },
  OUT_LOGIN:(state)=>{
      state.userInfo = {}
      state.isUserInfo = false
  }
},

  modules: {
  }
})

