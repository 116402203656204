import axios from "axios";
import { ElMessage } from "element-plus";

const service = axios.create({
  baseURL: "/japi", //本地
  // baseURL: "/japi",  //线上
  timeout: 5000, // request timeout
});
service.defaults.headers.post["Content-Type"] =
  "appliaction/x-www-form-urlencoded";
// request interceptor
service.interceptors.request.use(
  (config) => {
    // if (sessionStorage.getItem("token") == null) {
    //   return config;
    // } else {
    //   config.headers.Authorization = JSON.parse(
    //     sessionStorage.getItem("token")
    //   );
    //   return config;
    // }
    if (localStorage.getItem("token") == null) {
      return config;
    } else {
      config.headers.Authorization = JSON.parse(
        localStorage.getItem("token")
      );
      return config;
    }
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response;
    return res.data;
  },
  (error) => {
    // 请求报错处理
    ElMessage.error(error.message);
  }
);
export default {
  service,
  postFormData(url, params, pathParams, timeout) {
    // console.log(url)
    if (pathParams) {
      var param = "";
      for (let key in pathParams) {
        param += `${key}=${pathParams[key]}&`;
      }
      param = param.substring(0, param.length - 1);
      url += `?${param}`;
    }
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: JSON.parse(sessionStorage.getItem("token")),
          Authorization: JSON.parse(localStorage.getItem("token")),
          
        },
        transformRequest: [
          function (data) {
            if (data) {
              const formData = new FormData();
              Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
              });
              return formData;
            } else {
              return;
            }
          },
        ],
        url,
        method: "post",
        data: params,
        timeout: timeout || axios.defaults.timeout,
      })
        .then((res) => {
          //console.log(res)
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
