export function https() {
  // pc端
  var targetProtocol = "https:";
  if (
    window.location.protocol != targetProtocol && window.location.hostname != "localhost"
  ) {
    window.location.href = targetProtocol + window.location.href.substring(window.location.protocol.length);
  }
  //移动端
  // var mobilehref = window.location.href.replace("www.", "m.");
  var mobilehref;
  if (window.location.hostname.startsWith("www.")) {
    mobilehref = window.location.href.replace("www.", "m.");
  } else {
    mobilehref = "https://m.zhiwuyakaoyan.com"
  }
  mobilehref = mobilehref.replace(
    "http://localhost:9595",
    "https://m.zhiwuyakaoyan.com"
  );
  if (
    navigator.userAgent
      .toLowerCase()
      .match(/windows mobile|android|ucweb|iphone|ipad|mqqbrowser/i)
  ) {

    if (/.*\/article\/\d+\.shtm$/i.test(mobilehref)) {
      mobilehref = mobilehref.replace(
        /\/article\/(\d+)\.shtm$/gi,
        "/subPackages/article/article?id=$1"
      );
    }
    //(二版)
    // 免费试听
    if (/.*\/freevideo\/(\d+)\/(\d+)\/(\d+).shtm$/i.test(mobilehref)) {
      mobilehref = mobilehref.replace(/\/freevideo\/(\d+)\/(\d+)\/(\d+).shtm$/ig, '/subPackages/courseDetail/courseDetail?id=$1&freeclass=true&pid=$2&vid=$3')
    };
    //选课中心
    if (/.*\/freevideo\/(\d+).shtm$/i.test(mobilehref)) {
      mobilehref = mobilehref.replace(/\/freevideo\/(\d+).shtm$/ig, '/subPackages/courseDetail/courseDetail?id=$1')
    };
    var pathname = window.location.pathname
    if (pathname.indexOf('secret') != -1) {      
      return
    }
    window.location.href = mobilehref;
  }
}