<template>
  <!-- 首页头部 -->
  <div>
    <div class="header-bar">
      <div class="header-left">
        <div class="head-logo">
          <img src="@/assets/image/header/logo-1.png" @click="this.$router.push({ path: '/' })" />
        </div>
        <div class="dom-title" :class="domTitle != '知无涯考研'? 'line' :'' ">{{ domTitle != '知无涯考研'? domTitle :'' }}</div>
      </div>
      <div class="header-right">
        <div class="page-selection" @click="this.$router.push({ path: '/' })">
          <span>首页</span>
        </div>
        <div class="page-selection" v-if="isUserInfo" @click="this.$router.push({ path: '/mybought' })">
          <span>学习中心</span>
        </div>
        <div class="page-selection" @click="this.$router.push({ path: '/freeclass' })">
          <span>免费试听</span>
        </div>
        <div class="page-selection" @click="this.$router.push({ path: '/selectcourse' })">
          <span>选课中心</span>
        </div>
        <div class="page-selection" @click="toAboutUs">
          <span>关于我们</span>
        </div>
        <div class="register page-selection not-logged-in-btn ">
          <span class="mobile-terminal-text">手机学习</span>
          <img src="https://www.guojiayikao.com/static/images/newindex/down1.png" alt="" class="mobile-terminal-icon">
          <div class="head-prompt-box mobile-terminal-box downloaddiv">
            <!-- <div class="log-in-now mobile-terminal">
              <span>
              <a :href="this.load" download="user-card-16922397530000.5774938773868694.apk" >安卓端</a>
              </span>
            </div>
            <div class="divider-line"></div>
            <div class="log-in-now mobile-terminal">
              <a href="https://apps.apple.com/cn/app/%E7%9F%A5%E6%97%A0%E6%B6%AF%E8%80%83%E7%A0%94/id6450402705"> IOS端</a></div> -->
              <img src="./downloadapp.png" alt="" class="downloadimg">
          </div>
        </div>
        <div class="register-phone page-selection">
          <!-- <el-icon color="#c1c1c1" size="32">
            <svg t="1683881147989" class="icon" viewBox="0 0 1024 1024" version="1.1"  xmlns="http://www.w3.org/2000/svg" p-id="6772" width="200" height="200">
              <path
                d="M829.2 760.32l-33.408 33.264-8.352 8.32-8.352 8.32c-41.76 41.584-183.776 99.792-434.368-141.408-233.888-232.896-167.056-374.288-133.648-415.872l58.464-58.24c25.072-24.944 66.832-24.944 91.888 0l83.536 83.2c25.056 24.944 25.056 66.528 0 91.472l-50.112 41.6-8.352 16.64c-25.072 24.944-25.072 33.264 8.352 66.528l125.28 116.448c58.48 49.92 66.832 49.92 91.888 33.28l41.76-49.92c25.072-24.96 66.832-33.28 91.904-8.32l83.52 83.184c25.072 24.96 25.072 66.544 0 91.488z"
                fill="#c1c1c1" p-id="6773"></path>
            </svg>
          </el-icon> -->
          <el-icon size="22" color="#A6A6A6"><Phone /></el-icon>
          <a class="phone" tel="400-650-0069">400-650-0069</a>
        </div>
        
        <div class="register page-selection not-logged-in-btn" @click="LoginBoxBtn()" v-if="!isUserInfo">
          <img src="@/assets/image/header/avatar.png" alt="" />
          <span>登录</span>
          |
          <div class="head-prompt-box">
            <div class="enroll">登录/注册后，可享受</div>
            <div class="prompt-icon">精彩课程免费试听</div>
            <div class="log-in-now">立即登录</div>
          </div>
        </div>
        <div class="register page-selection not-logged-in-btn" @click="LoginBoxBtn()" style="margin-left: -10px;" v-if="!isUserInfo">
          <span>注册</span>
          <div class="head-prompt-box">
            <div class="enroll">登录/注册后，可享受</div>
            <div class="prompt-icon">精彩课程免费试听</div>
            <div class="log-in-now">立即注册</div>
          </div>
        </div>

        <!-- 个人中心 -->
        <div class="register page-selection not-logged-in-btn register_userinfo" v-else>
          <img :src="userInfo.avatar" alt=""  @click="this.$router.push({ path: '/usercenter' })"/>
          <span @click="this.$router.push({ path: '/usercenter' })">{{userInfo.nickname}}</span>
          <div class="personal-popup">
            <div class="list-item" @click="this.$router.push({ path: '/myorder' })"><div><el-icon><List /></el-icon>&nbsp;我的订单</div><el-icon><ArrowRight /></el-icon></div>
            <div class="list-item" @click="this.$router.push({ path: '/mybought' })"><div><el-icon><Management /></el-icon>&nbsp;我的课程</div><el-icon><ArrowRight /></el-icon></div>
            <div class="list-item" @click="this.$router.push({ path: '/usercenter' })"><div><el-icon><UserFilled /></el-icon>&nbsp;账号设置</div><el-icon><ArrowRight /></el-icon></div>
            <div @click="OutLogin">安全退出</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <LoginBox ref="LoginBox" :dialogVisible="dialogVisible" :LoginBoxClose="LoginBoxClose"></LoginBox> -->
</template>

<script>
import LoginBox from "@/components/LoginBox/LoginBox";  
import { downloadMobileTerminal  } from "@/api/free";
export default {
  name: "DsEpidemicViewHeader",
  components: {
    LoginBox,
  },
  props:[
    'domTitle'
  ],
  data() {
    return {
      dialogVisible: false,
      inputContent: "",
      userInfo: {},
      isUserInfo: false,
      load:'',
    };
  },
  watch: {
    "$store.state.userInfo"() {
      this.userInfo = this.$store.state.userInfo;
      this.isUserInfo = this.$store.state.isUserInfo;
    },
  },
  mounted() {
    // if (sessionStorage.getItem("userInfo") != null) {
    //   this.$store.commit(
    //     "SET_USERINFO",
    //     JSON.parse(sessionStorage.getItem("userInfo"))
    //   );
    // } 
    //获取手机端下载链接
    this.downloadphone()

    if (localStorage.getItem("userInfo") != null) {
      this.$store.commit(
        "SET_USERINFO",
        JSON.parse(localStorage.getItem("userInfo"))
      );
    } 
  },
  updated(){

  },

  methods: {
    //下载Android手机端app
    async downloadphone(){
      
      // const params ={
      //   id:1,
      // };
       //调取接口
      downloadMobileTerminal().then((res)=>{
       
  
        if (res.code == 200) {
          this.load = res.data.appDownloadUrl 
          // window.open(this.load)
        }
       
       })

    },
    LoginBoxBtn() {
      // this.$refs.LoginBox.handleShow()
      this.$parent.LoginBoxClose()
      // this.dialogVisible = true;
    },
    LoginBoxClose() {
      this.$parent.LoginBoxClose()
      // this.dialogVisible = false;
    },
    OutLogin() {
      // sessionStorage.removeItem("userInfo");
      localStorage.removeItem('userInfo')
      this.$store.commit("OUT_LOGIN");
      // sessionStorage.removeItem("token");
      localStorage.removeItem('token')
      this.$router.push({ path: "/" });
    },
    toAboutUs() {
      const url = this.$router.resolve({
        path: `/article/5` + '.shtm',
      }).href;
      window.open(url, "_blank");
      
    },
  },
};
</script>



<style   scoped>
.header-bar {
  width: 1200px;
  height: 66px;
  margin: 0 auto;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  /* overflow: hidden; */
}

.head-logo {
  width: 140px;
  cursor: pointer;
}
.dom-title{
  width: 164px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 37px;
  padding-left: 32px;
  position: relative;
  font-weight: bold;
}
.line::after{
  content: '';
  width: 1px;
  height: 21px;
  background: #D6DBE2;
  opacity: 0.67;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.head-logo>img {
  width: 100%;
  height: 100%;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
}

.head-input {
  width: 239px;
  margin-left: 20px;
  height: 45px;
  position: relative;
}

.seach-icon {
  width: 40px;
  height: 44px;
  position: absolute;
  right: 20px;
  top: 50%;
  border-left: 1px solid #eaeaea;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.seach-icon img {
  width: 16px;
  height: 16px;
}

.el-input {
  width: 100%;
  height: 100%;
  background: rgba(214, 219, 226, 0);
  border-radius: 2px;
  cursor: pointer;
}

.el-input /deep/ .el-input__wrapper {
  padding: 1px 45px 1px 10px !important;
}

input:focus {
  outline: none !important;
}

input:focus::-webkit-input-placeholder {
  font-size: 14px;
}

.header-right>div {
  margin: 0 7px;
}

.page-selection>span {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  color: #333333;
  margin: 0 10px;
}

.page-selection>span:hover {
  color: #1b67fd !important;
}

.downloadimg{
  width: 100px !important;
  height: 100px !important;
  border-radius: 0% !important;
  margin: 25px 25px;
}
.downloaddiv{
  width: 150px !important;
  height: 150px !important;
}
.head-prompt-box {
  width: 234px;
  height: 234px;
  position: absolute;
  padding: 30px;
  background: url("@/assets/image/header/head-bg.png") no-repeat;
  background-size: 100% 100%;
  top: 25px;
  right: -60px;
  border-radius: 10px;
  z-index: 100;
  display: none;
}

.personal-popup {
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 10;
  overflow: hidden;
  display: none;
  cursor: pointer;
  width: 186px;
  background: #FFFFFF;
  box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  padding: 16px;
  border-radius: 8px;
}

.personal-popup .list-item {
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.personal-popup .list-item >div{
  display: flex;
  align-items: center;
}
.personal-popup div:last-child{
  border-top: 1px solid #eaeaea;
  justify-content: center;
  padding-top: 16px;
  text-align: center;
  margin-top: 8px;
}
.personal-popup div:hover{
  color: #426BF6;
}

.head-prompt-box:hover {
  display: inline-block;
}

.register,
.register-phone {
  display: flex;
  align-items: center;
  position: relative;
}

.register_userinfo>span {
  display: inline-block;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.register-phone .phone{
  color: #666666;
}
.register-phone img {
  width: 26px;
  height: 26px;
  margin-right: 5px;
}

.register-phone>i {
  margin-right: 5px;
}

.register img {
  width: 33px;
  height: 33px;
  margin-right: 5px;
  transition: all 0.3s;
  border-radius: 50%;
  overflow: hidden;
}

.enroll {
  height: 20%;
  display: flex;
  font-size: 14px;
  color: #a7a7a7;
  border-bottom: 1px dashed #a7a7a7;
  justify-content: center;
  align-items: center;
}

.register>a {
  font-size: 16px;
  margin: 0 5px;
  color: #666666 !important;
}

.head-prompt-box>.prompt-icon {
  width: 80%;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.head-prompt-box>.prompt-icon::before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 26px;
  line-height: 26px;
  background: url("@/assets/image/header/heade-enroll.png") no-repeat;
  background-size: 90% 313%;
  margin-right: 10px;
}

.head-prompt-box>.prompt-icon:nth-child(3):before {
  background-position-y: -28px;
}

.head-prompt-box>.prompt-icon:nth-child(4):before {
  background-position-y: -53px;
}

.log-in-now {
  width: 80%;
  padding: 10px 0;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  background: linear-gradient(109deg, #045afe, #357bff, #367afc);
  box-shadow: 0px 0px 13px 0px rgba(54, 123, 255, 0.36);
  border-radius: 20px;
  cursor: pointer;
}


  

.mobile-terminal-text{
  margin-right: 3px !important;
}
.mobile-terminal-icon{
  width: 10px !important;
  height: 10px !important;
}
.mobile-terminal{
  margin: 25px 20px;
}
.mobile-terminal-box{
  height: 150px;
}
.mobile-terminal>span>a{
  display: inline-block;
  color: #ffffff ;
  width: 100%;
  height: 20px;
}
.mobile-terminal > a{
  display: inline-block;
  color: #ffffff ;
  width: 100%;
  height: 20px;
}


.not-logged-in-btn:hover .head-prompt-box {
  display: inline-block !important;
}

.not-logged-in-btn:hover .personal-popup {
  display: inline-block !important;
}

.not-logged-in-btn:hover img {
  transform: scale(1.5);
  z-index: 99;
}
</style>