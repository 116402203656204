<template>
  <footer class="footer-module">
    <!-- 底部蓝色区域 -->
    <div class="footer-module-title">
      <div class="footer-module-title-text">
        <div class="footer-module-topic">2024考研全面升级</div>
        <div class="footer-module-cue-word">优质好课等你来购</div>
      </div>
      <!-- <div class="footer-module-QR-code">
        <img src="@/assets/image/qr.jpg" alt="" />
      </div> -->
    </div>
    <!-- 最底部区域 -->
    <div class="footer-details">
      <div class="footer-details-classify">
        <div class="classification-list">
          <!-- 主页底部 -->
          <div class="footer-details-left">
            <div class="footer-details-list">
            <!-- 关于我们 -->
            <div class="footer-details-about">
              <h3 style="color: #333;margin-bottom:20px;">关于我们</h3>
              <ul>
                <li @click="goInfo(5)">企业介绍</li>
                <li @click="goInfo(6)">企业培训</li>
                <li @click="goInfo(7)">招贤纳士</li>
                <li @click="goInfo(8)">联系我们</li>
              </ul>
            </div>
            <!-- 新手帮助 -->
            <div class="footer-details-addition">
              <h3 style="color: #333;margin-bottom:20px;">新手帮助</h3>
              <ul>
                <li @click="goInfo(9)">网上报名流程</li>
                <li @click="goInfo(10)">联系客服</li>
                <li @click="goInfo(11)">校区查询</li>
                <li @click="goInfo(12)">常见问题</li>
              </ul>
            </div>
            <div class="footer-details-concern">
              <h3 class="details-concern-title" >关注或联系我们</h3>
              <div class="details-concern-option">添加小程序</div>
              <div class="details-concern-option">关注移动端服务</div>
              <div class="details-concern-btn">
                 <div><span @click="goInfo(10)">联系我们</span></div>
              </div>
            </div>
          </div>
          <div class="footer-details-icp">
          <span class="footer-details-icp-one">
            <a href="https://beian.miit.gov.cn"  target=_blank class="footer-details-icp-one-Jump">京ICP备19024315号</a> 
             &nbsp; 咨询邮箱：zhiyeyishi@aliyun.com  &nbsp; 
             <a href="https://image.guojiayikao.com/zbsyk-2020-02-27-20-25-45-zbsykzxjy-1582806345-707499577.jpeg" target="_blank" class="footer-details-icp-one-Jump">营业执照</a>
             &nbsp;  投诉电话：18701537533   传真:010-63588455  
          </span>
          <div class="footer-details-icp-margin-two">
            <span class="footer-details-icp-two">
              Copyright © 2022-2028 www.zhiwuyakaoyan.com All Rights Reserved  &nbsp; 北京浩然远达教育科技有限公司 版权所有
          </span>
          </div>
        </div>
          </div>
        </div>
        
        <!-- <div class="about-contact">
          <div class="contact-slogans">关注或联系我们</div>
          <div class="add-QR">添加小程序 关注移动端服务</div>
          <div class="QR-hidden">
            <div class="show-QR">
              <img src="../../assets/image/qr.jpg" alt="" />
            </div>
            <div class="show-QR">
              <img src="../../assets/image/m.png" alt="" />
            </div>
          </div>
          <div class="add-QR">
            咨询热线: <span><a href="">400-650-0069</a></span>
          </div>
          <div class="contact-us-btn">
            <div><a href="">联系我们</a></div>
          </div>
        </div> -->
        <div class="about-contact">
          <div class="about-contact-figure">
            <div class="show-QR">
              <img src="../../assets/image/qr.jpg" alt="" />
            </div>
            <div class="show-QR">
              <img src="../../assets/image/m.png" alt="" />
            </div>
          </div>
          <div class="add-QR">
            咨询热线: <span><a href="">400-650-0069</a></span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// import axios from 'axios';
export default {
  name: "DsEpidemicViewFoot",

  data() {
    return {};
  },

  mounted() {
    // axios.post(api.getVerify,(res)=>{
    //     console.log(res);
    // })
  },

  methods: {
    goInfo(id) {
      const url = this.$router.resolve({
        path: `/article/${id}` + '.shtm',
      }).href;
      window.open(url, "_blank");
    },
    
  },
};
</script>

<style  scoped>
.footer-module {
  width: 100%;
  height: 165px;
  background: url("@/assets/image/footer/foot-back.jpg") no-repeat;
  background-size: 100% 100%;
}

 .footer-module-title {
  width: 1180px;
  height: 165px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-module-topic {
  font-size: 32px;
  color: #fff;
  /* padding-top: 20px; */
  /* height: 50px; */
  /* line-height: 50px; */
  text-align: center;
  font-weight: bold;
}

.footer-module-title-text {
  height: 100%;
  padding: 37px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.footer-module-cue-word {
  font-size: 18px;
  color: #fff;
  /* height: 64px; */
  /* line-height: 64px; */
  text-align: center;
  /* margin-top: 20px; */
}

.footer-module-QR-code {
  position: absolute;
  width: 120px;
  height: 120px;
  background: #fff;
  top: 50%;
  right: 175px;
  transform: translate(0, -50%);
  border-radius: 15rpx;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-module-QR-code>img {
  width: 95%;
  height: 95%;
}

/* 最底部区域 */
.footer-details {
  background: #f6f7fb;
  background-color: #f6f6f6;
}

.footer-details-classify {
  padding: 30px 0 50px 0;
  width: 1200px;
  margin: 0 auto;
  display: flex;
}

.footer-details-list {
  padding-left: 5px;
  display: flex;
  font-size: 16px;

}

.footer-details-about {
  margin: 0px 80px 0 0px;
}

.footer-details-about>ul>li {
  margin: 10px auto;
  color: #666;
  font-size: 12px;
}

.footer-details-about>ul>li:hover {
  color: rgb(255, 91, 91);
  cursor: pointer;
  text-decoration: underline;
}

.footer-details-addition {
  margin-left: 90px;
  margin-right: 60px;
}

.footer-details-addition>ul>li {
  margin: 10px auto;
  color: #666;
  font-size: 12px;
}

.footer-details-addition>ul>li:hover {
  color: rgb(255, 91, 91);
  cursor: pointer;
  text-decoration: underline;
}

.footer-details-list:first-child {
  margin-left: 0;
}

.footer-details-list>ul>li {
  width: 100%;
  line-height: 30px;
  font-size: 14px;
  color: #666;
}
/* 2023.7.21 改格式 */
.footer-details-concern{
  width: 200px;
  height: 137px;
  margin: 0px 0px 0px 100px;
}
.details-concern-title{
  color: rgb(51, 51, 51);
    margin-bottom: 20px;
}
.details-concern-option{
  margin: 10px auto;
    color: #666;
    font-size: 12px;
}
.details-concern-btn{
  /* margin-top: 10px;
  cursor: pointer; */
  width: 230px;
  height: 49px;
  margin-top: 15px;
  
}
.details-concern-btn>div{
  display: inline-block;
  padding: 10px 60px;
  background: #0052d9;
}
.details-concern-btn>div>span{
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

/* ICP备案 */

.footer-details-icp{
  height: 60px;
  width: 800px;
  margin-top: 50px;
  margin-left: 200px;
}
.footer-details-icp-one{
  font-size: 12px;
  height: 16px;
  color: #9d9d9d;
}
.footer-details-icp-one-Jump{
  height: 16px;
  color: #9d9d9d;
}
.footer-details-icp-two{
  font-size: 12px;
  height: 16px;
  width: auto;
  color: #9d9d9d;
 
  
}
.footer-details-icp-margin-two{
  margin-top: 10px;
}
/* 小字区域 */
.classification-list {
  width: 60%;
  height: 150px;
  display: flex;
}

.about-contact {
  width: 40%;
  padding-left: 120px;
  height: 260px;
}
.about-contact-figure{
  display: flex;
  height: 120px;
}
.contact-slogans {
  line-height: 30px;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
  color: #333;
}

.add-QR {
  font-size: 16px;
  color: #666;
  line-height: 30px;
  margin: 10px 0px 0px 0px;
}

.QR-hidden {
  width: 100%;
  display: flex;
}

.add-QR span a {
  display: inline-block;
  font-size: 18px;
  color: #333;
  margin: 0 10px;
}

.contact-us-btn {
  margin-top: 10px;
  cursor: pointer;
}

.contact-us-btn>div {
  display: inline-block;
  padding: 15px 80px;
  background: #0052d9;
}

.contact-us-btn>div>a {
  color: #fff;
}

.show-QR {
  margin: 10px 0;
  width: 120px;
  height: 120px;
  margin-left: 30px;
}
.show-QR:hover,.footer-module-QR-code:hover{
  /* width: 150px;
  height: 150px; */
  transform: scale(1.5);
  -webkit-transition:all 0.2s linear;
  transition:all 0.2s linear;
}

.show-QR:first-child {
  margin-left: 0;
}

.show-QR img {
  width: 100%;
  height: 100%;
}</style>