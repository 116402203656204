import { createRouter, createWebHistory} from 'vue-router'
import LoginBox from '@/components/LoginBox/LoginBox'

const routes = [
    
    {
        path: '/',
        component: () => import('../view/index'),
        meta: {
            title: '知无涯考研'
        }
    },
    // 新登录页面
    {
        path: '/newlogin',
        component: () => import('../view/newlogin/newlogin.vue'),
        meta: {
            title: '新登录'
        }
    },
     // 免费试听
    {
        path: '/freeclass',
        component: () => import('../view/freeclass/freeclass'),
        meta: {
            title: '免费试听-知无涯考研'
        }
    },

    // 免费试听视频页(免费试听)
    {
        path: '/freevideo/:courseId/:pid?/:id?.shtm',
        name:"freevideo",
        component: () => import('../view/freeclass/component/freeVideo.vue'),
        meta: {
            // requiresAuth: true,
            title: '免费试听-知无涯考研'
        },
        key: '$route.fullPath'
    },
     // 免费试听视频页(选课中心)
     {
        path: '/freevideo/:courseId.shtm',
        name:"freevideo_course",
        component: () => import('../view/freeclass/component/freeVideo.vue'),
        meta: {
            title: '免费试听-知无涯考研'
        },
        key: '$route.fullPath'
    },


     // 选课中心
    {
        path: '/selectcourse',
        component: () => import('../view/selectcourse/selectcourse'),
        meta: {
            title: '选课中心-知无涯考研'
        }
    },
    //  学习中心看课
    {
        // path: '/learningVideo/:id?/:courseId?/:img?.shtm',
        path: '/learningVideo/:id?/:courseId.shtm',
        name:'learningVideo',
        component: () => import('../view/learninglearn/component/learningVideo.vue'),
        meta: {
            title: '学习中心-知无涯考研',
            requiresAuth: true
        }
    },
    //学习中心
    {
        path: '/mybought',
        component: () => import('../view/learninglearn/learninglearn.vue'),
        meta: {
            title: '学习中心-知无涯考研',
            requiresAuth: true
        }
    },

     // 个人中心
    {
        path: '/usercenter',
        component: () => import('../components/header/usercenter.vue'),
        meta: {
            title: '个人中心-知无涯考研',
            requiresAuth: true
        }
    },
     // 订单中心
    {
        path: '/myorder',
        component: () => import('../components/header/myorder.vue'),
        meta: {
            title: '订单中心-知无涯考研',
            requiresAuth: true
        }
    },
     // 隐私协议
    {
        path: '/secret',
        component: () => import('../view/secret/secret.vue'),
        meta: {
            title: '隐私协议-知无涯考研'
        }
    },
    //修改密码
    {
        path: '/password',
        name:'password',
        component: () => import('../components/header/password.vue'),
        meta: {
            title: '修改密码-知无涯考研',
            requiresAuth: true
        }
    },
    // 忘记密码，修改密码
    {
        path: '/ForgetPassword',
        component: () => import('../view/forgetPassword/forgetPassword.vue'),
        meta: {
            title: '忘记密码-知无涯考研'
        }
    },
    // 关于我们
    {
        path: '/article/:id?.shtm',
        component: () => import('../view/article/article.vue'),
        meta: {
            title: '关于我们-知无涯考研'
        }
    },



]

const router = createRouter({
    mode: 'hash', // 设置路由模式为history
    history: createWebHistory(),
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
})


//路由守卫
router.beforeEach((to, from, next) => {
    // const token = sessionStorage.getItem('token')
    const token = localStorage.getItem('token')
    // console.log(token);
    if (to.matched.some(record => record.meta.requiresAuth)) { // 判断该路由是否需要登录才能访问
        if (token == null) { // 判断用户是否已经登录
          next({
            path: '/',
           })
        } else {
            next()
        }
      } else {
        next()
      }
});

export default router


